import React from "react";

const SubmitButton = ({ isSubmitting, children, icon }) => {
  return (
    <button
      type="submit"
      className="rbt-btn btn-md btn-gradient hover-icon-reverse w-100"
      disabled={isSubmitting}
    >
      <span className="icon-reverse-wrapper">
        <span className="btn-text">{children}</span>
        {icon && (
          <span className="btn-icon">
            <i className="feather-arrow-right"></i>
          </span>
        )}
      </span>
    </button>
  );
};

export default SubmitButton;
