import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { signIn } from "next-auth/react";
import { registerSchema } from "@/schemas/authSchemas";
import PasswordInput from "@/components/Common/Form/PasswordInput";
import SubmitButton from "@/components/Common/Button/SubmitButton";
import SocialLogin from "./SocialLogin";
import TextInput from "../Common/Form/TextInput";

const RegisterForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm({
    resolver: yupResolver(registerSchema),
  });

  const onSubmit = async (values) => {
    console.log(values);
    try {
      const res = await signIn("credentials", {
        redirect: false,
        email: values.email,
        password: values.password,
        type: "register",
        firstName: values.firstName,
        lastName: values.lastName,
      });

      if (res.error) {
        setError("email", { type: "manual", message: res.error });
      } else {
        console.log("Kayıt başarılı");
      }
    } catch (error) {
      console.error("Kayıt sırasında hata oluştu:", error);
    }
  };

  return (
    <div className="rbt-contact-form contact-form-style-1 max-width-auto">
      <h3 className="title">Kayıt Ol</h3>
      <form className="max-width-auto" onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          name="firstName"
          placeholder="Ad *"
          register={register}
          errors={errors}
        />
        <TextInput
          name="lastName"
          placeholder="Soyad *"
          register={register}
          errors={errors}
        />
        <TextInput
          name="email"
          type="email"
          placeholder="Email adresi *"
          register={register}
          errors={errors}
        />
        <PasswordInput
          name="password"
          placeholder="Şifre *"
          register={register}
          errors={errors}
        />
        <PasswordInput
          name="confirmPassword"
          placeholder="Şifreyi Onayla *"
          register={register}
          errors={errors}
        />
        <div className="form-submit-group">
          <SubmitButton isSubmitting={isSubmitting} icon>
            Kayıt Ol
          </SubmitButton>
        </div>
      </form>
      <SocialLogin register />
    </div>
  );
};

export default RegisterForm;
