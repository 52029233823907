import React from "react";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";
import { useSession } from "next-auth/react";

const Login = () => {
  const { data: session, status } = useSession();

  console.log(session?.user);
  return (
    <>
      <div className="col-lg-6">
        <LoginForm />
      </div>

      <div className="col-lg-6">
        <RegisterForm />
      </div>
    </>
  );
};

export default Login;
