// schemas/authSchemas.js
import * as Yup from "yup";

export const registerSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("Ad gereklidir")
    .min(2, "Ad en az 2 karakter olmalıdır")
    .max(50, "Ad en fazla 50 karakter olabilir"),
  lastName: Yup.string()
    .required("Soyad gereklidir")
    .min(2, "Soyad en az 2 karakter olmalıdır")
    .max(50, "Soyad en fazla 50 karakter olabilir"),
  email: Yup.string()
    .email("Geçersiz email adresi")
    .required("Email adresi gereklidir")
    .max(100, "Email en fazla 100 karakter olabilir"),
  password: Yup.string()
    .required("Şifre gereklidir")
    .min(6, "Şifre en az 6 karakter olmalıdır")
    .max(100, "Şifre en fazla 100 karakter olabilir"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Şifreler eşleşmiyor")
    .required("Şifre onayı gereklidir"),
});

export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Geçersiz email adresi")
    .required("Email adresi gereklidir")
    .max(100, "Email en fazla 100 karakter olabilir"),
  password: Yup.string()
    .required("Şifre gereklidir")
    .min(6, "Şifre en az 6 karakter olmalıdır")
    .max(100, "Şifre en fazla 100 karakter olabilir"),
});

export const passwordUpdateSchema = Yup.object().shape({
  currentpassword: Yup.string().required("Mevcut şifre gereklidir"),
  newpassword: Yup.string()
    .required("Yeni şifre gereklidir")
    .min(6, "Yeni şifre en az 6 karakter olmalıdır"),
  retypenewpassword: Yup.string()
    .oneOf([Yup.ref("newpassword"), null], "Şifreler eşleşmelidir")
    .required("Yeni şifreyi tekrar giriniz"),
});

