import React from "react";

function GoogleIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width="18px"
      height="18px"
    >
      <path
        fill="#4285F4"
        d="M24 9.5c3.9 0 6.6 1.7 8.1 3.1l6-6C34.9 2.6 30.2 0 24 0 14.7 0 7 6.3 3.8 14.9l7.4 5.7c1.5-4.6 5.8-8.1 10.8-8.1z"
      />
      <path
        fill="#34A853"
        d="M46.1 24.5c0-1.5-.1-3-0.3-4.5H24v9h12.5c-.5 2.5-2 4.6-4.1 6l6.3 4.9c3.7-3.4 5.8-8.4 5.8-14.4z"
      />
      <path
        fill="#FBBC05"
        d="M10.7 28.4c-0.5-1.5-0.7-3.1-0.7-4.7s0.3-3.2 0.7-4.7L3.3 13c-1.5 3-2.3 6.4-2.3 10s0.8 7 2.3 10l7.4-5.6z"
      />
      <path
        fill="#EA4335"
        d="M24 48c6.5 0 12-2.1 16-5.6l-7.4-5.7c-2.1 1.4-4.9 2.3-8.6 2.3-4.9 0-9.2-3.2-10.8-7.6l-7.4 5.7C7 41.8 14.7 48 24 48z"
      />
    </svg>
  );
}

export default GoogleIcon;
