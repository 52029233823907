import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { signIn } from "next-auth/react";
import { loginSchema } from "@/schemas/authSchemas";
import SocialLogin from "./SocialLogin";
import PasswordInput from "@/components/Common/Form/PasswordInput";
import Checkbox from "@/components/Common/Form/Checkbox";
import SubmitButton from "@/components/Common/Button/SubmitButton";
import TextInput from "../Common/Form/TextInput";

const LoginForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const onSubmit = async (data) => {
    const res = await signIn("credentials", {
      redirect: false,
      email: data.email,
      password: data.password,
      type: "login",
    });

    if (res.error) {
      setError("email", { type: "manual", message: res.error });
    } else {
      console.log("Giriş başarılı");
      // Giriş başarılı, yönlendirme yapabilir veya başka işlemler gerçekleştirebilirsiniz.
    }
  };

  return (
    <div className="rbt-contact-form contact-form-style-1 max-width-auto">
      <h3 className="title">Giriş Yap</h3>
      <form className="max-width-auto" onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          name="email"
          type="text"
          placeholder="Kullanıcı adı veya email *"
          register={register}
          errors={errors}
        />
        <PasswordInput
          name="password"
          placeholder="Şifre *"
          register={register}
          errors={errors}
        />
        <div className="row mb--30">
          <div className="col-lg-6">
            <Checkbox
              name="rememberme"
              label="Beni hatırla"
              register={register}
            />
          </div>
          <div className="col-lg-6">
            <div className="rbt-lost-password text-end">
              <a className="rbt-btn-link" href="#">
                Şifrenizi mi unuttunuz?
              </a>
            </div>
          </div>
        </div>
        <div className="form-submit-group">
          <SubmitButton isSubmitting={isSubmitting} icon>
            Giriş Yap
          </SubmitButton>
        </div>
      </form>
      <SocialLogin register={false} />
    </div>
  );
};

export default LoginForm;
