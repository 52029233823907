import React from "react";

const TextInput = ({ name, type = "text", placeholder, register, errors }) => {
  return (
    <div className="form-group">
      <input
        name={name}
        type={type}
        placeholder={placeholder}
        {...register(name)}
      />
      {errors[name] && (
        <div className="text-danger">{errors[name].message}</div>
      )}
      <span className="focus-border"></span>
    </div>
  );
};

export default TextInput;
