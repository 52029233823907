import React from "react";

const PasswordInput = ({ name, placeholder, register, errors }) => {
  return (
    <div className="form-group">
      <input
        name={name}
        type="password"
        placeholder={placeholder}
        {...register(name)}
      />
      {errors[name] && <div className="text-danger">{errors[name].message}</div>}
      <span className="focus-border"></span>
    </div>
  );
};

export default PasswordInput;
