import React from "react";

const Checkbox = ({ name, label, register }) => {
  return (
    <div className="rbt-checkbox">
      <input type="checkbox" id={name} {...register(name)} />
      <label htmlFor={name}>{label}</label>
    </div>
  );
};

export default Checkbox;
